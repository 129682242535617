/* global google */
import SktDb from '../sktDb';
import ItemSort from '../itemSort';
import getCookie from '../getCookie';
import findGetParameter from '../findGetParameter';
import insertParam from './../insertParam';
import addcsvquotes from './../addcsvquotes';
import convertTimeToTimezone from './../convertTimeToTimezone';
import { data } from 'jquery';

import itemSort from '../itemSort';

let reportAscending = true;
let reportSorting, assignedClicker, oldAssignedClicker;
let firstPass = true;
let filterData = false;

let lastAssignedClicker;

export default class Reports {
	
  static registerEvents() {
	window.reportItems = Array();
	  
    try {
      google.charts.load('current', { packages: ['line', 'corechart'] });
    } catch (e) {
      console.log('Google charts could not be loaded');
    }

    $('.card-body').on('click', '#reports-submit', async (e) => {
      e.preventDefault();
      lastAssignedClicker = 1;
      reportSorting = 0;
      resetDefaultProps();
      const startDate = $("input[name='startdate']").val();
      const endDate = $("input[name='enddate']").val();
      const locationId = $("select[name='location']").val();
      const locationType = $("select[name='location']")
        .find(':selected')
        .data('type');
      const barcodeId = $("input[name='barcode_autofill_id']").val();
      let queryParams = `start=${startDate}&end=${endDate}&locationtype=${locationType}&locationid=${locationId}`;
      queryParams += barcodeId !== undefined ? `&barcodeid=${barcodeId}` : '';

      switch ($(location).attr('pathname')) {
        case '/reports/reports-individual-product': {
          const response = await fetch(
            '/api/get-individual-report?' + queryParams
          );
          const data = await response.json();
          filterData = data;

          if (data) {
            this.drawSingleProductChart(data);
          } else {
            $('#single-product-report').html(
              'No report data available for this item yet.'
            );
          }
          break;
        }
        case '/reports/use-by-location-report': {
          const response = await fetch(
            '/api/use-by-location-report?' + queryParams
          );
          const data = await response.json();
          filterData = data;

          $('#locations-report tbody').html(
            '<tr><th>Product:</th><th>Starting at:</th><th>Ending at:</th><th>Difference:</th><th>Comment:</th></tr>'
          );
          this.addDataToLocationsReport(data);
          break;
        }
        case '/reports/chronological-history-report': {
          const response = await fetch(
            '/api/get-chronological-report?' + queryParams
          );
          const data = await response.json();
          filterData = data;
		  
		  console.log(data);

          $('#locations-report tbody').html('<tr><th>Transactions:</th></tr>');
          this.addDataToChronologicalReport(data);
          break;
        }
        case '/reports/usage-type-report': {
          const response = await fetch(
            '/api/get-usage-type-report?' + queryParams
          );
          const data = await response.json();
          filterData = data;

          data.forEach((item) => {
            if (!isNaN(parseInt(item[1]))) item[1] = parseInt(item[1]);
            return item;
          });
          this.drawUsageByTypeChart(data);
          break;
        }
        case '/reports/consumption-report': {
          console.log('/api/use-by-consumption-report?' + queryParams);

          const response = await fetch(
            '/api/use-by-consumption-report?' + queryParams
          );
          const data = await response.json();
          console.log(data);
          filterData = data;

          $('#locations-report tbody').html(
            '<tr><th>SKU:</th><th>Product:</th><th>Quantity:</th><th>Type:</th><th>User:</th><th>Comment:</th></tr>'
          );
          this.addDataToConsumptionReport(data);
          break;
        }
        case '/reports/restock-report': {
          const response = await fetch(
            '/api/use-by-restock-report?' + queryParams
          );
          const data = await response.json();
          filterData = data;

          $('#locations-report tbody').html(
            '<tr><th>Item #</th><th>Item Name</th><th>Total Restock:</th><th>User:</th><th>Comment:</th></tr>'
          );
          this.addDataToRestockReport(data);
          break;
        }
        case '/reports/transfers-report': {
          const response = await fetch(
            '/api/get-transfer-report?' + queryParams
          );
          const data = await response.json();
          console.log(data);

          filterData = data;

          $('#locations-report tbody').html(
            '<tr><th>Transfer Date and Time:</th><th>Product:</th><th>From Room:</th><th>To Room:</th><th>Transaction Quantity:</th><th>User:</th><th>Comment:</th></tr>'
          );
          this.addDataToTransferReport(data, false);
          break;
        }

        case '/manage/items': {
          console.log('archive');
        }
      }

      $('#reports-toggleable').css('opacity', 1);

      // filterData = data

      // assignClickHandler(scopedData)
    });
  }

  // Draws google chart for single product report page
  static drawSingleProductChart(data = []) {
    const headerEle = data.shift();
    data.forEach((ele) => {
      ele[0] = new Date(ele[0]);
    });
    data.unshift(headerEle);
    const options = {
      title: 'Individual Product Usage',
      legend: { position: 'bottom' },
      chartArea: { width: '100%' },
      interpolateNulls: true,
      hAxis: { showTextEvery: 1 },
    };
    data = google.visualization.arrayToDataTable(data);
    const chart = new google.charts.Line(
      document.getElementById('single-product-report')
    );
    chart.draw(data, google.charts.Line.convertOptions(options));
  }

  // Draws google chart for usage by type report
  static drawUsageByTypeChart(data = []) {
    data = google.visualization.arrayToDataTable(data);
    const options = {
      legend: { alignment: 'center' },
      chartArea: { left: 0, width: '100%', height: '90%' },
      pieSliceText: 'value-and-percentage',
    };
    const chart = new google.visualization.PieChart(
      document.getElementById('single-product-report')
    );
    chart.draw(data, options);
  }

  // Adds data to table in Locations Report Page.
  static async addDataToLocationsReport(data) {
    await Promise.all(
      data.map(async (item) => {
        const barcode = await SktDb.getRecord('barcode', item.barcode_id);
        item.barcode = barcode;
        item.barcode_name = barcode.product_name;
      })
    );

    data = sorterFunction(data);
	
	//create a list for our CSV Download
	const csvItems = ({
      start_of_period,
      end_of_period,
      barcode_name,
    }) => {
		const tempItem = [
		  `"${addcsvquotes(start_of_period) || 'Undefined'}"`,
		  `"${addcsvquotes(end_of_period) || 'Undefined'}"`,
		  `"${addcsvquotes(barcode_name) || 'Undefined'}"`,
		];
		window.reportItems.push(tempItem);
	}
	//add headers as an array
	window.reportItems = Array([
		`"Start Time"`,
		`"End Time"`,
		`"Barcode Name"`,
	]);
	//mapping to generate the 'push' of each line
	data.map(csvItems);

    const LocationReport = ({
      start_of_period,
      end_of_period,
      barcode_name,
    }) => `
      <tr><td>${
        barcode_name || 'Undefined'
      }</td><td>${start_of_period}</td><td>${end_of_period}</td><td>${
      end_of_period - start_of_period
    }</td><td>${note}</td></tr>
    `;
    $('#locations-report tbody').append(data.map(LocationReport).join(''));
  }

  // TODO - ADD to Consumption / Restock
  /*
  Add SKU column
  Add Action type column
  Absolute value ints
  */
  // Adds data to table in Consumption Report
  static async addDataToConsumptionReport(data) {
	
    await Promise.all(
      data.map(async (item) => {
        const barcode = await SktDb.getRecord('barcode', item.barcode_id);
        item.barcode = barcode;

        item.barcode_name = barcode.product_name;
      })
    );

    console.log(data);

    data = sorterFunction(data);
	
	//create a list for our CSV Download
	const csvItems = ({
      barcode,
      barcode_name,
      total_consumption,
      general_use_total,
      waste_total,
      user_full,
	  note,
    }) => {
		let consumption_type = 'general';
		if(waste_total > 0){
			consumption_type = 'waste';
		}
		
		
		const tempItem = [
		  `"${addcsvquotes(barcode.barcode) || 'Undefined'}"`,
		  `"${addcsvquotes(barcode_name) || 'Undefined'}"`,
		  `"${addcsvquotes(total_consumption) || 'Undefined'}"`,
		  `"${addcsvquotes(consumption_type) || 'Undefined'}"`,
		  `"${addcsvquotes(user_full) || 'Undefined'}"`,
		  `"${addcsvquotes(note) || 'No Comments'}"`,
		];
		
		window.reportItems.push(tempItem);
	}
	//add headers as an array
	window.reportItems = Array([
		`"Barcode"`,
		`"Product Name"`,
		`"Consumption Qty"`,
		`"Consumption Type"`,
		`"User"`,
		`"Comment"`,
	]);
	//mapping to generate the 'push' of each line
	data.map(csvItems);

    // Create table
    const LocationReport = ({
      barcode,
      product_name,
      total_consumption,
      general_use_total,
      waste_total,
      user_full,
	  note,
    }) => {
		
	let note_notice = '';
	if(note != null){
		note_notice = `
			${note}
		`;
	}
	
	let consumption_type = 'general';
	if(waste_total > 0){
		consumption_type = 'waste';
	}
	
	return(`
      <tr><td>${barcode.barcode}</td><td>${product_name}</td><td>${total_consumption}</td><td>${consumption_type}</td><td>${user_full}</td><td>${note_notice}</td></tr>
    `);}

    // Push Table to dom
    $('#locations-report tbody').append(data.map(LocationReport).join(''));
  }



  // Adds data to table in Restock
  static async addDataToRestockReport(data) {
    await Promise.all(
      data.map(async (item) => {
        let barcode = await SktDb.getRecord('barcode', item.barcode_id);
        // console.log(barcode)
        item.barcode = barcode;
        item.barcode_name = barcode.product_name;
      })
    );

    data = sorterFunction(data);
	
	//create a list for our CSV Download
	const csvItems = ({
      barcode,
      barcode_name,
      total_restocked,
      user_full,
	  note,
    }) => {
		const tempItem = [
		  `"${addcsvquotes(barcode.barcode) || 'Undefined'}"`,
		  `"${addcsvquotes(barcode_name) || 'Undefined'}"`,
		  `"${addcsvquotes(total_restocked) || 'Undefined'}"`,
		  `"${addcsvquotes(user_full) || 'Undefined'}"`,
		  `"${addcsvquotes(note) || ''}"`,
		];
		window.reportItems.push(tempItem);
	}
	//add headers as an array
	window.reportItems = Array([
		`"Barcode"`,
		`"Product Name"`,
		`"Restock Qty"`,
		`"User"`,
		`"Comment"`,
	]);
	//mapping to generate the 'push' of each line
	data.map(csvItems);
	
    // Create table
    const LocationReport = ({
      barcode,
      barcode_name,
      total_restocked,
      user_full,
	  note,

      // barcode_name,
    }) => {
		let note_notice = '';
		if(note != null){
			note_notice = `
				${note}
			`;
		}
		return(`
		  <tr><td>${barcode.barcode}</td><td>${barcode_name}</td><td>${total_restocked}</td><td>${user_full}</td><td>${note_notice}</td></tr>
	`);}
    // Push Table to dom
    $('#locations-report tbody').append(data.map(LocationReport).join(''));
  }

  // Adds data to table in Chronological Report Page.
  static async addDataToChronologicalReport(data) {
    // console.log(this.uxTime)
    await Promise.all(
      data.map(async (item) => {
        const barcode = await SktDb.getRecord('barcode', item.barcode_id);
        item.barcode = barcode;

        item.barcode_name = barcode.product_name;
      })
    );
    // console.log(data)
    // console.log(transferHistorySorting(data))
    data = sorterFunction(data); //organize the data
	console.log('Report Data');
	console.log(data);  
	
	//create a list for our CSV Download
	const csvItems = ({
      transaction_quantity,
      barcode_name,
      room_name,
      creation_time,
	  note,
    }) => {
		const tempItem = [
		  `"${addcsvquotes(transaction_quantity) || 'Undefined'}"`,
		  `"${addcsvquotes(barcode_name) || 'Undefined'}"`,
		  `"${addcsvquotes(room_name) || 'Undefined'}"`,
		  `"${addcsvquotes(creation_time) || 'Undefined'}"`,
		  `"${addcsvquotes(note) || 'No Comments'}"`,
		];
		
		window.reportItems.push(tempItem);
	}
	//add headers as an array
	window.reportItems = Array([
		`"Transaction Qty"`,
		`"Product Name"`,
		`"Room"`,
		`"Creation Time"`,
		`"Comment"`,
	]);
	//mapping to generate the 'push' of each line
	data.map(csvItems);

    const ChronologicalReport = ({
      transaction_quantity,
      barcode_name,
      room_name,
      creation_time,
      user_full,
      note,
    }) => {
		
		if(note != null){	
			return `
				<tr>
					<td>
						<b>
						${quantityAdjustPhrasing(
							transaction_quantity,
							barcode_name
						)}
						</b> from
						${room_name} with comment
						(${note}) ON
						${convertTimeToTimezone(creation_time)} 
						by ${user_full}
					</td>
				<tr>`;
		}else{
		
			return `
				<tr>
					<td>
						${convertTimeToTimezone(creation_time)} | <b>${quantityAdjustPhrasing(
							transaction_quantity,
							barcode_name
						)}
						
						</b> ${room_name} by ${user_full}
					</td>
				</tr>
			`;
		}
	}
	
    $('#locations-report tbody').append(data.map(ChronologicalReport).join(''));
  }

  static async addDataToTransferReport(data, bool) {
    if (bool) {
      await Promise.all(
        data.map(async (item) => {
          const barcode = await SktDb.getRecord('barcode', item.barcode_id);

          item.barcode = barcode;

          item.barcode_name = barcode.product_name;
        })
      );

      data = transferHistorySorting(data);
    }

    console.log(data);
	
	//create a list for our CSV Download
	const csvItems = ({
      transaction_quantity,
      product_name,
      to_room,
      from_room,
      transfer_date_and_time,
      user_full,
	  note,
    }) => {
		const tempItem = [
		  `"${addcsvquotes(transaction_quantity) || 'Undefined'}"`,
		  `"${addcsvquotes(product_name) || 'Undefined'}"`,
		  `"${addcsvquotes(to_room) || 'Undefined'}"`,
		  `"${addcsvquotes(from_room) || 'Undefined'}"`,
		  `"${addcsvquotes(transfer_date_and_time) || 'Undefined'}"`,
		  `"${addcsvquotes(user_full) || 'Undefined'}"`,
		  `"${addcsvquotes(note) || 'Undefined'}"`,
		];
		
		window.reportItems.push(tempItem);
	}
	
	//add headers as an array
	window.reportItems = Array([
		`"Transaction Qty"`,
		`"Product Name"`,
		`"To Room"`,
		`"From Room"`,
		`"Transfer Time"`,
		`"User"`,
		`"Comment"`,
	]);
	//mapping to generate the 'push' of each line
	data.map(csvItems);

    const ChronologicalReport = ({
      transaction_quantity,
      product_name,
      to_room,
      from_room,
      transfer_date_and_time,
	  user_full,
	  note,
    }) => {
	let note_notice = '';
	if(note != null){
		note_notice = `
			${note}
		`;
	}
	return(`
      <tr><td>${transfer_date_and_time}</td><td>${product_name}</td><td>${from_room}</td><td>${to_room}</td><td>${transaction_quantity}</td><td>${user_full}</td><td>${note_notice}</td></tr>
    `);}
    $('#locations-report tbody').append(data.map(ChronologicalReport).join(''));
  }
}

// Used to create readable date in desired format
function uxTime(mysqltime) {
  const date = new Date(mysqltime);
  let resultString = '';
  resultString += date.getMonth() + 1 + '/';
  resultString += date.getDate() + '/';
  resultString += date.getFullYear() + ' ';
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours || 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  const strTime = hours + ':' + minutes + ampm + ' EST';
  resultString += strTime;
  return resultString;
}

// Used in chronological report to format log sentence
function quantityAdjustPhrasing(adjustment, barcodeName) {
  let resultString = '';
  resultString += Math.abs(adjustment);
  resultString += ' ' + barcodeName;
  resultString += adjustment > 0 ? ' added to ' : ' removed from ';
  return resultString;
}

function transferHistorySorting(data) {
  // Take incoming data, return a new array of objects w/ tranfer reports

  const returnArr = [];
  let currentEntry, lastEntry, nextEntry;
  // If first in array is 0, save as lastEntry

  for (let i = 0; i < data.length; i++) {
    currentEntry = data[i];
    nextEntry = data[i + 1];
    currentEntry.previous_room = false;

    if (currentEntry.transaction_quantity < 0) {
      currentEntry.previous_room = true;
    }

    if (i == 0) {
      lastEntry = currentEntry;
    } else {
      if (
        currentEntry.barcode_id == lastEntry.barcode_id &&
        currentEntry.creation_time == lastEntry.creation_time &&
        Math.abs(currentEntry.transaction_quantity) ==
          Math.abs(lastEntry.transaction_quantity)
      ) {
        // Create new object and push to return array
        if (
          lastEntry.room_name == currentEntry.room_name ||
          currentEntry.transaction_quantity == 0
        ) {
          continue;
        }
        if (currentEntry.previous_room == false) {
          returnArr.push({
            barcode_name: currentEntry.barcode_name,
            creation_time: currentEntry.creation_time,
            transaction_quantity: currentEntry.transaction_quantity,
            previous_room_name: lastEntry.room_name,
            current_room_name: currentEntry.room_name,
            barcode: currentEntry.barcode,
          });
        }
      } else if (
        data[i + 1] &&
        currentEntry.barcode_id == nextEntry.barcode_id &&
        currentEntry.creation_time == nextEntry.creation_time &&
        Math.abs(currentEntry.transaction_quantity) ==
          Math.abs(nextEntry.transaction_quantity)
      ) {
        if (
          nextEntry.room_name == currentEntry.room_name ||
          currentEntry.transaction_quantity == 0
        ) {
          continue;
        }

        if (currentEntry.previous_room == false) {
          returnArr.push({
            barcode_name: currentEntry.barcode_name,
            creation_time: currentEntry.creation_time,
            transaction_quantity: currentEntry.transaction_quantity,
            previous_room_name: nextEntry.room_name,

            current_room_name: currentEntry.room_name,
            barcode: currentEntry.barcode,
          });
        }
      }

      lastEntry = currentEntry;
    }
  }

  return returnArr;
}

// Sort Items Fucntions Below
// INSTRUCTIONS:
/*
    Use sorterFunction() to return data sorted within report generation function
    Add difference cases based on the type of data it may be filtering.
    IE: Table column name w/ ':' removed and spaces replaced with '_'
    Add column name w/ proper filtering to sorterFunction() case
    Add page path w/ report generation function to assignClickHandler() case
    IMPORTANT: Add #sorting to table  in .php to enable clickable sorting

  */
function sorterFunction(data) {
  if (filterData !== data) {
    filterData = data;
  }
  // console.log(data)
  // Filter out archived items
  //filterData = new itemSort(data).filterArchived(true);
  //filterData = filterData.items;
  // console.log(data)

  // ('<tr><td>Transfer Time:</td><td>Product:</td><td>Previous Room Name:</td><td>Current Room Name:</td><td>Transaction Quantity:</td></tr>')
  switch (reportSorting) {
    case 'product':
      filterData.sort(function (a, b) {
        const nameA = a.barcode_name.toUpperCase();
        const nameB = b.barcode_name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      break;
    case 'transfer_time':
      filterData.sort(function (a, b) {
        const nameA = a.creation_time;
        const nameB = b.creation_time;
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      break;
    case 'previous_room_name':
      filterData.sort(function (a, b) {
        const nameA = a.previous_room_name;
        const nameB = b.previous_room_name;
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      break;
    case 'current_room_name':
      filterData.sort(function (a, b) {
        const nameA = a.current_room_name;
        const nameB = b.current_room_name;
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      break;
    case 'transaction_quantity':
      filterData.sort(function (a, b) {
        const nameA = a.transaction_quantity;
        const nameB = b.transaction_quantity;
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      break;
    case 'starting_at':
      filterData.sort(function (a, b) {
        const nameA = a.start_of_period;
        const nameB = b.start_of_period;
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      break;
    case 'ending_at':
      filterData.sort(function (a, b) {
        const nameA = a.end_of_period;
        const nameB = b.end_of_period;
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      break;
    case 'difference':
      filterData.sort(function (a, b) {
        const nameA = a.end_of_period - a.start_of_period;
        const nameB = b.end_of_period - b.start_of_period;
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      break;
    case 'transactions':
      filterData.reverse();
      break;
    default:
      break;
  }

  $('#sorting span').remove();
  if (!assignedClicker) {
    // return filterData
    assignedClicker = $('#sorting tbody th').first();
  }

  resetDefaultProps();

  try {
    if (reportSorting.replace(':', '') !== oldAssignedClicker) {
      oldAssignedClicker = reportSorting;
      reportAscending = false;
    } else {
      filterData.reverse();
    }
  } catch (error) {}

  if (!reportAscending) {
    reportAscending = true;
    // ARROW UP
    assignedClicker.append('<span>&#8595</span>');
  } else {
    reportAscending = false;
    if (!firstPass) {
      // ARROW DOWN
      assignedClicker.append('<span>&#8593</span>');
    } else {
      firstPass = false;
    }
  }

  return filterData;
}

// Assign Handler

$('#sorting tbody').on('click', 'th', function (e) {
  e.preventDefault();

  console.log('click');
  const param = $(this)[0]
    .outerText.slice(0, -1)
    .toLowerCase()
    .replace(' ', '_');

  reportSorting = param;
  removeTD('#locations-report td');
  assignedClicker = $(this);

  // Set correct reload
  // Add path here to generate
  switch ($(location).attr('pathname')) {
    case '/reports/reports-individual-product': {
      Reports.drawSingleProductChart(filterData);
      break;
    }
    case '/reports/use-by-location-report': {
      Reports.addDataToLocationsReport(filterData);
      break;
    }
    case '/reports/chronological-history-report': {
      Reports.addDataToChronologicalReport(filterData);
      break;
    }
    case '/reports/usage-type-report': {
      Reports.drawUsageByTypeChart(filterData);
      break;
    }
    case '/reports/consumption-report': {
      Reports.addDataToConsumptionReport(filterData);
      // increment++
      // console.log('Increment:' + increment)
      break;
    }
    case '/reports/restock-report': {
      Reports.addDataToRestockReport(filterData);
      break;
    }
    case '/reports/transfers-report': {
      Reports.addDataToTransferReport(filterData, false);
      break;
    }
  }
  assignedClicker.off();
});

function removeTD(path) {
  $(path).remove();
}

function resetDefaultProps() {
  if (!lastAssignedClicker == reportSorting) {
    lastAssignedClicker = reportSorting;
    reportAscending = true;
    assignedClicker = null;
    firstPass = true;
    console.log('reset');
  }
}
